<template>
  <el-drawer
    :title="drawerTitle"
    custom-class="g-drawer"
    :wrapper-closable="false"
    :visible="drawerVisible"
    size="600px"
    @open="open"
    @close="close"
  >
    <div class="g-drawer-c">
      <el-form ref="form" :model="drawerForm" label-width="80px" label-position="top" :rules="drawerRules" :disabled="formDisabled">
        <el-row :gutter="8">
          <el-col :span="12">
            <el-form-item label="关联用户" prop="ext1">
              <div style="line-height: 15px;height: 38px;">
                <UserInfoCard :need-change-user-info="false" :need-match="false" :user-info="companyInfo" :show-bg="true" />
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="地址简称"
              prop="addrName"
            >
              <el-input v-model.trim="drawerForm.addrName" maxlength="16" placeholder="请输入" clearable />
            </el-form-item>
          </el-col>
        </el-row>
        <el-form ref="form2" :model="form2" label-width="80px" label-position="top" :disabled="formDisabled">
          <el-form-item class="map-address" label="地图选址">
            <!-- v-if="!searchData.value && !isInit"  -->
            <div v-if="showErrorTip" class="map-address-error">请输入地址</div>
            <div class="map-panel">
              <div class="map-search">
                <MapSearch v-model="searchData" :search-options="searchOptions" @search="searchMap" />
              </div>
              <div id="map" class="map" />
            </div>
          </el-form-item>
        </el-form>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item
              label="三级地址"
              prop="addrRegion"
            >
              <el-input v-model="drawerForm.addrRegion" placeholder="选取坐标点后自动显示" disabled />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="地址类型"
              prop="addrType"
            >
              <el-select v-model="drawerForm.addrType" class="g-inp" clearable placeholder="请选择">
                <el-option v-for="item in resAddrTypeData.A" :key="item.value" :label="item.label" :value="item.value" />
                <!-- <el-option value="生产厂家" label="生产厂家" />
                <el-option value="库区" label="库区" />
                <el-option value="停车场" label="停车场" />
                <el-option value="维修站" label="维修站" />
                <el-option value="加油站" label="加油站" />
                <el-option value="其他" label="其他" /> -->
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item
          class="detail"
          label="详细地址"
          prop="addrDetail"
        >
          <el-input v-model.trim="drawerForm.addrDetail" placeholder="选取坐标点后自动显示" disabled />
        </el-form-item>
        <el-form-item
          label="联系单位"
          prop="custName"
        >
          <el-input v-model.trim="drawerForm.custName" placeholder="请输入" clearable />
        </el-form-item>
        <el-row :gutter="20">
          <el-col :span="12" :offset="0">
            <el-form-item
              label="联系人"
              prop="custUserName"
            >
              <el-input v-model="drawerForm.custUserName" placeholder="请输入" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12" :offset="0">
            <el-form-item
              label="联系电话"
              prop="custUserPhone"
            >
              <el-input v-model.trim="drawerForm.custUserPhone" placeholder="请输入" maxlength="11" clearable />
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="备注" prop="remark">
          <el-input v-model="drawerForm.remark" type="textarea" placeholder="请输入" clearable />
        </el-form-item>
      </el-form>
    </div>
    <div class="g-btn-c">
      <el-button @click="close">取消</el-button>
      <el-button v-if="drawerTypeDesc==='add'||drawerTypeDesc==='edit'" type="primary" @click="submit">提交</el-button>
      <el-button v-if="drawerTypeDesc==='detail'" type="danger" @click="deleteClick">删除</el-button>
      <el-button v-if="drawerTypeDesc==='detail'" type="primary" @click="changeEdit">编辑</el-button>
      <!-- <el-button @click="submitFun(true)">提交并继续新增</el-button>
          <el-button type="primary" @click="submitFun(false)">提交</el-button> -->
    </div>
  </el-drawer>
</template>

<script>
import { validateName, validateTel } from '@/utils/validateRule';
import MapSearch from './mapSearch';
import AMapLoader from '@amap/amap-jsapi-loader';
import axios from 'axios';
import { addAddr, getDetailAddr, editAddr, deleteAddr } from '@/api/address';
// import { recover } from '@/utils';
export default {
  components: {
    MapSearch,
  },
  props: {
    // 详情类型 add 新增 edit编辑 detail详情
    drawerType: {
      type: String,
      default() {
        return 'add';
      },
    },
    drawerVisible: {
      type: Boolean,
      default() {
        return false;
      },
    },
    drawerId: {
      type: [String, Number],
      default() {
        return false;
      },
    },
    companyInfo: {
      type: Object,
      default() {
        return {
          // companyId: null,
        };
      },
    },
  },
  data() {
    return {
      resAddrTypeData: {
        A: [],
        O: {},
      },
      drawerTypeDesc: '',
      drawerTitleObj: {
        'add': '新增地址',
        'edit': '编辑地址',
        'detail': '查看地址',
      },
      companyList: [],
      drawerForm: {
        addrName: '', // 地址名称
        addrRegion: '', // 三级地址
        addrDetail: '', // 详细地址
        addrPoint: '', // 经纬度
        addrType: '', // 地址类型
        custName: '', // 客户名称
        custUserName: '', // 联系人姓名
        custUserPhone: '', // 联系电话
      },
      drawerRules: {
        addrName: [
          { required: true, message: '请输入地址简称', trigger: 'blur' },
        ],
        addrRegion: [
          { required: true, message: '请输入地址' },
        ],
        addrDetail: [
          { required: true, message: '请输入地址' },
        ],
        custUserName: [
          { required: false, validator: validateName('请输入有效的联系人名称') },
        ],
        custUserPhone: [
          { required: false, validator: validateTel },
        ],
      },
      searchData: { label: '', value: '', detail: '', data: null },
      searchOptions: [],
      form2: {
        mapSearchKey: '',
      },
      showErrorTip: false,
      validateName,
      validateTel,
    };
  },
  computed: {
    formDisabled() {
      return this.drawerTypeDesc === 'detail';
    },
    drawerTitle() {
      return this.drawerTitleObj[this.drawerTypeDesc];
    },
  },
  watch: {
    // 监听路由变化，如果发生变化则关闭drawer
    '$route': function(to, from) {
      this.drawerVisible = false;
    },
  },
  methods: {
    open() {
      this.resAddrTypeData = this.$parent.resAddrTypeData || {};
      this.drawerTypeDesc = this.drawerType;
      if (this.drawerTypeDesc === 'detail' || this.drawerTypeDesc === 'edit') {
        this.getDetail();
      } else {
        this.searchOptions = [];
        // this.drawerForm.company.id = this.companyInfo.id;
        this.initMap();
      }
    },
    close() {
      this.drawerForm = {
        addrName: '', // 地址名称
        addrRegion: '', // 三级地址
        addrDetail: '', // 详细地址
        addrPoint: '', // 经纬度
        addrType: '', // 地址类型
        custName: '', // 客户名称
        custUserName: '', // 联系人姓名
        custUserPhone: '', // 联系电话
      };
      this.form2.mapSearchKey = '';
      this.searchData = { label: '', value: '', detail: '', data: null };
      this.$refs.form.resetFields();
      this.$emit('update:drawerVisible', false);
    },
    initMap() {
      AMapLoader.load({
        key: '2ce614857c44af9c3cc5f2691e537ed9', // 申请好的Web端开发者Key，首次调用 load 时必填
        version: '1.4.15', // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: ['AMap.Geocoder'], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
        AMapUI: {
          // 是否加载 AMapUI，缺省不加载
          version: '1.1', // AMapUI 缺省 1.1
          plugins: [], // 需要加载的 AMapUI ui插件
        },
        Loca: {
          // 是否加载 Loca， 缺省不加载
          version: '1.3.2', // Loca 版本，缺省 1.3.2
        },
      })
        .then((AMap) => {
          this.mapInstance = new AMap.Map('map');
          this.geocoder = new AMap.Geocoder();
          this.marker = new AMap.Marker();
          this.AMap = AMap;
          this.mapInstance.on('click', (e) => {
            this.handleMapClick(e.lnglat);
          });
          // if (!this.dialogData.flag) {
          if (this.drawerForm.addrPoint) {
            // this.drawerForm.lnglat

            const lnglatStr = this.drawerForm.addrPoint;
            // let obj = lnglat.split(",");
            const [lng, lat] = lnglatStr.split(',');
            const lnglat = new AMap.LngLat(lng, lat);
            this.handleMapClick(lnglat);
          }
          // }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    searchMap(searchData) {
      if (searchData) {
        const lnglatArr = searchData.value.split(',');
        this.drawerForm.addrPoint = searchData.value;
        const currentData = searchData.data;
        // const lnglat = new AMap.LngLat(+lnglatArr[0], +lnglatArr[1]);
        // const lnglat = this.AMap.LngLat(+lnglatArr[0], +lnglatArr[1]);
        if (currentData.cityname) {
          if (currentData.pname && currentData.adname) {
            this.drawerForm.addrRegion = `${currentData.pname}${currentData.cityname}${currentData.adname}`;
          } else if (currentData.pname && !currentData.adname) {
            this.drawerForm.addrRegion = `${currentData.pname}${currentData.cityname}`;
          } else if (!currentData.pname && currentData.adname) {
            this.drawerForm.addrRegion = `${currentData.cityname}${currentData.adname}`;
          } else {
            this.drawerForm.addrRegion = `${currentData.cityname}`;
          }
        } else {
          if (currentData.pname && currentData.adname) {
            this.drawerForm.addrRegion = `${currentData.pname}-${currentData.adname}`;
          } else if (currentData.pname && !currentData.adname) {
            this.drawerForm.addrRegion = `${currentData.pname}`;
          } else if (!currentData.pname && currentData.adname) {
            this.drawerForm.addrRegion = `${currentData.adname}`;
          } else {
            this.drawerForm.addrRegion = '';
          }
        }
        this.marker.setPosition(lnglatArr);
        // this.marker.setPosition(lnglat);
        this.mapInstance.add(this.marker);
        this.mapInstance.setFitView(this.marker);
        this.mapInstance.setZoom(12);
        this.drawerForm.addrDetail = searchData.detail;
        this.showErrorTip = false;
      }
    },
    searchLngLat(address) {
      axios.get(`https://restapi.amap.com/v3/place/text?keywords=${address}&key=cef3abe94cbfafe4e30b79415ee51f0b`).then((res) => {
        const { data } = res;
        if (data.status === '1' && data.pois.length) {
          const lnglatArr = data.pois[0].location.split(',');
          const currentData = data.pois[0];
          if (currentData.cityname) {
            if (currentData.pname && currentData.adname) {
              this.drawerForm.addrRegion = `${currentData.pname}${currentData.cityname}${currentData.adname}`;
            } else if (currentData.pname && !currentData.adname) {
              this.drawerForm.addrRegion = `${currentData.pname}${currentData.cityname}`;
            } else if (!currentData.pname && currentData.adname) {
              this.drawerForm.addrRegion = `${currentData.cityname}${currentData.adname}`;
            } else {
              this.drawerForm.addrRegion = `${currentData.cityname}`;
            }
          } else {
            if (currentData.pname && currentData.adname) {
              this.drawerForm.addrRegion = `${currentData.pname}${currentData.adname}`;
            } else if (currentData.pname && !currentData.adname) {
              this.drawerForm.addrRegion = `${currentData.pname}`;
            } else if (!currentData.pname && currentData.adname) {
              this.drawerForm.addrRegion = `${currentData.adname}`;
            } else {
              this.drawerForm.addrRegion = '';
            }
          }
          this.drawerForm.addrPoint = data.pois[0].location;
          // const lnglat = new AMap.LngLat(+lnglatArr[0], +lnglatArr[1]);
          this.marker.setPosition(lnglatArr);
          this.mapInstance.add(this.marker);
          this.mapInstance.setFitView(this.marker);
          this.mapInstance.setZoom(12);
          this.drawerForm.addrDetail = `${data.pois[0].address && typeof data.pois[0].address === 'string' ? data.pois[0].address : data.pois[0].name}`;
          this.searchData = {
            label: data.pois[0].name,
            value: data.pois[0].location,
            detail: `${data.pois[0].address && typeof data.pois[0].address === 'string' ? data.pois[0].address : data.pois[0].name}`,
            data: data.pois[0],
          };
          this.searchOptions = data.pois.map((item) => {
            return {
              value: item.location,
              label: item.name,
              detail: `${item.pname}${item.cityname}${item.adname}${item.address && typeof item.address === 'string' ? item.address : item.name}`,
              data: item,
            };
          });
          this.showErrorTip = false;
        } else {
          this.$baseMessage('根据地址查询位置失败', 'error');
        }
      });
    },
    handleMapClick(lnglat) {
      this.geocoder.getAddress(lnglat, (status, result) => {
        if (status === 'complete' && result.regeocode) {
          const address = result.regeocode.formattedAddress;
          this.searchLngLat(address);
        }
      });
    },
    getDetail() {
      getDetailAddr({ id: this.drawerId }).then((res) => {
        // this.drawerForm = recover(this.drawerForm, res);
        this.drawerForm = res;

        this.initMap();
      });
    },
    deleteClick() {
      const params = {
        id: this.drawerId,
      };
      deleteAddr(params).then(() => {
        this.$baseMessage('删除成功!', 'success');
        this.$emit('drawerSubmit');
        this.close();
      });
    },
    changeEdit() {
      this.drawerTypeDesc = 'edit';
    },

    submit() {
      if (!this.drawerForm.addrDetail) {
        this.showErrorTip = true;
      }
      // this.drawerForm.mapAddress = this.searchData.value;
      // type 获取type 分别对应api提交
      this.$refs.form.validate(async(valid) => {
        if (valid) {
          try {
            const drawerForm = JSON.parse(JSON.stringify(this.drawerForm));
            const apiMap = {
              'add': addAddr,
              'edit': editAddr,
            };
            const res = await apiMap[this.drawerTypeDesc](drawerForm);
            if (res) {
              this.$baseMessage(`${this.drawerTitle}成功!`, 'success');
              this.$emit('drawerSubmit');
              this.close();
            }
          } catch (error) {
            console.log(error);
          }
          // const addParams = {
          //   ...this.companyInfo,
          //   ...drawerForm,
          // };
        }
      });
    },
  },

};

</script>
<style lang="scss">
.map-address {
  position: relative;
  .map-address-error {
    position: absolute;
    top: 293px;
    left: 0;
    color: #ff4d4f;
    font-size: 12px;
  }
}
.map-address .el-form-item__error {
  top: -28px;
  left: 80px;
}

.coordinate-container .el-form-item__content {
  margin-top: -10px;
}
</style>

<style scoped lang="scss">
::v-deep {
  .el-input-group__append button.el-button {
    background: #409eff;
    color: white;
  }
}
.map-panel {
  width: 525px;
  position: relative;
  .map-search {
    position: absolute;
    z-index: 100;
    padding: 12px;
    width: 100%;
  }
  .map {
    width: 100%;
    height: 300px;
    border: 1px solid #ddd;
  }
}
</style>
