<template>
  <div class="basic-data-wrap flex">
    <leftPanel ref="leftPanel" :class="['left-wrap',isFold?'left-half':'left-all']" :is-fold.sync="isFold" company-type="all" @changeCompany="changeCompany" @leftCompanyData="leftCompanyData" />
    <div :class="['right-wrap g-search-form-table',isFold?'right-half':'right-all']">
      <search-form id="search-form" :model="form" :count="2" label-width="auto" :collapse="false" @search="searchHandle">
        <search-item label="" prop="addrName">
          <el-input v-model.trim="form.addrName" clearable placeholder="地址简称" />
        </search-item>
        <!-- <search-item label="" prop="addrRegion">
          <el-input v-model.trim="form.addrRegion" clearable placeholder="省市区" />
        </search-item> -->
        <search-item label="" prop="addrDetail">
          <el-input v-model.trim="form.addrDetail" clearable placeholder="详细地址" />
        </search-item>
      </search-form>
      <Table
        :loading="loading"
        :table-height="tableHeight"
        :show-selection-change="true"
        :table-cols="tableCols"
        :table-data="tableData"
        :table-btns="tableBtns"
        :pagination="pagination"
        :multiple-selection.sync="multipleSelection"
        @selectTime="selectTime"
        @changeSort="changeSort"
        @sizeChange="sizeChange"
        @pageChange="pageChange"
      />
      <Drawer :drawer-visible.sync="drawerVisible" :drawer-type="drawerType" :drawer-id="id" :company-info="companyInfo" @drawerSubmit="drawerSubmit" />
    </div>
  </div>
</template>

<script>
import { getAddr, batchDeleteAddr } from '@/api/address';
import { exportByJson, parseTime } from '@/utils';
import leftPanel from '@/views/basicData/components/leftPanel';
import Drawer from './drawer';
export default {
  name: 'BasicdataUsualaddress',
  components: { leftPanel, Drawer },
  filters: { parseTime },
  data() {
    return {
      isFold: false,
      tableHeight: 'auto',
      companyInfo: {}, // 当前所选的左侧公司信息 相关接口需要此参数
      loading: false,
      form: {},
      tableBtns: [
        {
          label: '新增地址',
          icon: 'el-icon-circle-plus-outline',
          evt: () => {
            this.openDialog('add');
          },
        },
        {
          label: '删除',
          type: 'danger',
          // plain: true,
          evt: () => {
            this.delete();
          },
        },
        {
          label: '导出',
          type: 'default',
          evt: () => {
            this.onExport();
          },
        },
      ],
      tableCols: [
        {
          label: '地址简称', prop: 'addrName', fixed: 'left', type: 'jump', width: 150, evt: (data) => { this.openDialog('detail', data); },
        },
        // { label: '省市区', prop: 'addrRegion' },
        { label: '详细地址', prop: 'addrDetail', width: 200 },
        { label: '创建人', prop: 'createUsername' },
        { label: '创建时间 ', prop: 'createTime', type: 'filterTime', width: '160' },
        { label: '更新人', prop: 'updateUsername' },
        { label: '更新时间', prop: 'updateTime', sortable: 'custom', headTimeSelect: true, width: 170, type: 'filterTime' },
        {
          label: '操作',
          type: 'operation',
          fixed: 'right',
          btns: [
            {
              label: '编辑',
              evt: (data) => {
                this.openDialog('edit', data);
              },
            },
          ],
        },
      ],
      tableData: [],
      multipleSelection: [],
      pagination: {
        page: 1,
        size: 10,
        total: 0,
      },
      id: null,
      drawerType: 'add',
      drawerVisible: false,
    };
  },
  methods: {
    // 获取列表数据
    getList() {
      this.loading = true;
      const queryForm = JSON.parse(JSON.stringify(this.form));
      const params = {
        createUser: this.$store.state.common.AUV_USER_ID,
        ...queryForm,
        current: this.pagination.page,
        size: this.pagination.size,
      };
      getAddr(params)
        .then((res) => {
          this.tableData = res?.records || [];
          this.pagination = {
            page: res?.current || 1,
            size: res?.size || 10,
            total: res?.total || 0,
          };
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // 查询按钮操作
    searchHandle() {
      // 防止点击重置置空以下参数影响筛选
      this.form.companyId = this.companyInfo.companyId; // 企业id
      this.getList();
    },
    sizeChange(size) {
      this.pagination.page = 1;
      this.pagination.size = size;
      this.getList();
    },
    pageChange(page) {
      this.pagination.page = page;
      this.getList();
    },
    // 删除所选
    delete() {
      if (!this.multipleSelection.length) {
        this.$baseMessage('您还未勾选,请先勾选!', 'error');
        return;
      }
      const params = {
        ids: this.multipleSelection.map((d) => d.id) || null,
      };
      batchDeleteAddr(params).then((res) => {
        this.$baseMessage('删除成功!', 'success');
        this.getList();
      });
    },
    // 前端导出excel
    onExport() {
      const queryForm = JSON.parse(JSON.stringify(this.form));
      const params = {
        createUser: this.$store.state.common.AUV_USER_ID,
        ...queryForm,
        current: 1,
        size: 9999,
      };
      getAddr(params)
        .then((res) => {
          if (!res) {
            this.$baseMessage('暂无数据可导出!', 'error');
            return;
          }
          const header = ['地址简称', '省市区', '详细地址', '创建人', '创建时间', '更新人', '更新时间'];
          const exportData = res.records.map((item) => {
            return {
              addrName: item.addrName,
              addrRegion: item.addrRegion,
              addrDetail: item.addrDetail,
              createUsername: item.createUsername,
              createTime: parseTime(item.updateTime),
              updateUsername: item.updateUsername,
              updateTime: parseTime(item.updateTime),
            };
          });
          exportByJson(exportData, header, '地址列表.xlsx');
        })
        .catch((err) => {
          console.log(err);
          this.$baseMessage('导出失败', 'error');
        });
    },
    changeSort(val) {
      console.log('[ val ] >', val);
    },
    selectTime(time) {
      this.form.updateTimeBegin = time ? time[0] : '';
      this.form.updateTimeEnd = time ? time[1] : '';
      this.getList();
    },
    // 打开侧面抽屉 进行新增/编辑/查看操作
    openDialog(type, data) {
      this.id = data?.id || null;
      this.drawerType = type;
      this.drawerVisible = true;
    },
    // 抽屉提交事件
    drawerSubmit() {
      this.getList();
    },
    // left返回的id
    leftCompanyData(data) {
      this.companyInfo = data;
      this.form.companyId = data.companyId;
      this.getList();
      // this.getDispatch();
    },
    // 左侧切换公司 重新发起请求
    changeCompany(data) {
      this.companyInfo = data;
      this.form.companyId = data.companyId;
      this.getList();
      this.form.ext1 = null;
      // this.getDispatch();
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/basicData.scss";
</style>
