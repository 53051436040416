<template>
  <div class="map-search-container">
    <el-select
      v-model.trim="localValue"
      class="map-search-select"
      :class="isQuick ? 'quick-select' : 'non-quick-select'"
      filterable
      remote
      reserve-keyword
      :clearable="clearable"
      :placeholder="placeholder || '输入地址查询位置'"
      :remote-method="remoteMethod"
      :loading="loading"
      @blur="handleBlur"
      @focus="handleFocus"
      @clear="handleClear"
    >
      <el-option v-for="(item, index) in options" :key="item.value + index" :label="item.label" :value="item" />
    </el-select>
    <el-button v-if="!isQuick" type="primary" @click="handleClick">搜索</el-button>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'MapSearch',
  props: {
    clearable: {
      type: Boolean,
      default: true,
    },
    placeholder: {
      type: String,
      default: '',
    },
    // 是否有搜索按钮
    isQuick: {
      type: Boolean,
      default: false,
    },
    // 全部选项
    searchOptions: {
      type: Array,
      default: () => {
        return [];
      },
    },
    // 选中对象
    value: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      loading: false,
      options: [],
      localValue: '',
    };
  },
  watch: {
    options: {
      deep: true,
      handler(val) {
        if (!val || !val.length) {
          this.$emit('clear');
        }
      },
    },
    localValue: {
      deep: true,
      handler(val) {
        if (val && this.isQuick) {
          this.handleClick();
        }
      },
    },
    searchOptions: {
      deep: true,
      handler(val) {
        this.options = val;
      },
    },
    value: {
      deep: true,
      handler(val) {
        if (val) {
          this.localValue = val;
        }
      },
    },
  },
  methods: {
    handleBlur() {
      this.$emit('blur');
    },
    handleClear() {
      this.$emit('clear');
    },
    handleClick() {
      this.$emit('search', this.localValue);
    },
    handleFocus() {
      if (!this.localValue) {
        this.options = [];
      }
    },
    remoteMethod(query) {
      if (query) {
        this.loading = true;
        axios
          .get(`https://restapi.amap.com/v3/place/text?keywords=${query}&key=cef3abe94cbfafe4e30b79415ee51f0b`)
          .then((res) => {
            // 120.539968,32.066142
            const { data } = res;
            if (data.status === '1' && data.pois.length) {
              this.options = data.pois.map((item) => {
                return {
                  value: item.location,
                  label: item.name,
                  detail: `${item.pname === item.cityname ? '' : item.pname}${item.cityname}${item.adname}${item.address && typeof item.address === 'string' ? item.address : item.name}`,
                  data: item,
                };
              });
              this.$emit('success', data.pois);
            } else {
              this.$baseMessage('根据地址查询位置失败', 'error');
              this.$emit('error');
            }
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.options = [];
        this.localValue = '';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.map-search-container {
  display: flex;
  .quick-select {
    ::v-deep {
      .el-input,
      .el-input__inner {
        border-radius: 4px;
      }
    }
  }
  .non-quick-select {
    ::v-deep {
      .el-input,
      .el-input__inner {
        border-radius: 4px 0 0 4px;
      }
    }
  }
  ::v-deep {
    .el-button {
      border-radius: 0 4px 4px 0;
      background-color: #409eff;
      border: none;
    }
  }
}
</style>
